<template>
  <div class="paid-detail-component">
    <div class="paid-name">1.基本信息</div>
    <div class="paid-arrange">
      <div class="paid-arrange-setWidth paid-arrange-sort">
        <div class="title">内容记录</div>
        <div class="content">{{ data.content }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">项目类型</div>
        <div class="content">{{ data.projectName }}</div>
      </div>
      <div class="paid-arrange-sort">
        <!-- <div class="title">房间/单位名称</div>
        <div class="content">{{ data.positionDescName }}</div> -->
        <div class="title">所属公司</div>
        <div class="content">{{ data.enterpriseDescName }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">联系人</div>
        <div class="content">{{ data.contactPerson }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">联系电话</div>
        <div class="content">{{ data.contactPhone }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">经办客服</div>
        <div class="content">{{ data.ordPersonName }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">问题类型</div>
        <div class="content">{{ data.issueType }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">事件类型</div>
        <div class="content">
          {{ data.isOrderEvent == "Y" ? "工单事件" : "非工单事件" }}
        </div>
      </div>
      <div class="paid-arrange-sort" v-if="data.isOrderEvent == 'Y'">
        <div class="title">工单模板</div>
        <div class="content">
          {{ data.orderTemplateName }}
        </div>
      </div>
    </div>
    <div class="paid-name">2.处理结果</div>
    <div v-if="data.isOrderEvent == 'N'">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="5">
            <el-form-item label="处理人" prop="name">
              <el-input
                v-model="ruleForm.name"
                :disabled="isResults"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="处理部门" prop="department">
              <el-input
                v-model="ruleForm.department"
                :disabled="isResults"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="处理时间" prop="processTime">
              <el-date-picker
                :disabled="isResults"
                v-model="ruleForm.processTime"
                type="datetime"
                value-format="timestamp"
                placeholder="选择处理时间"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="处理结果" prop="processResults">
              <el-input
                v-model="ruleForm.processResults"
                :disabled="isResults"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!isResults">
          <el-form-item align="right">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >保存</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div class="paid-arrange" v-else>
      <div class="paid-arrange-sort">
        <div class="title">责任部门</div>
        <div class="content">{{ data.orderClientVO.resDeptName }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">开始执行时间</div>
        <div class="content">{{ data.orderClientVO.startDate || "-" }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">工单执行人</div>
        <div class="content">
          {{ data.orderClientVO.handlePersonName || "-" }}
        </div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">执行人电话</div>
        <div class="content">
          {{ data.orderClientVO.handlePersonPhone || "-" }}
        </div>
      </div>
      <div class="workOrder" @click="jumpToRwork">查看工单详情</div>
    </div>
    <div v-if="data.reviewStatus != 3">
      <div class="paid-name">3.回访满意度</div>
      <div>
        <el-form
          :model="followUp"
          :rules="rulefollow"
          ref="followUp"
          label-position="top"
          label-width="100px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="处理情况" prop="situation">
                <el-radio-group
                  v-model="followUp.situation"
                  size="medium"
                  :disabled="isfollowUp"
                >
                  <el-radio border :label="1">不满意</el-radio>
                  <el-radio border :label="2">基本不满意</el-radio>
                  <el-radio border :label="3">很满意</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="内容记录"
                prop="contentRecord"
                class="auto-height-item"
              >
                <el-input
                  :disabled="isfollowUp"
                  style="width: 30%"
                  type="textarea"
                  :rows="5"
                  maxlength="250"
                  placeholder="请描述具体内容"
                  v-model="followUp.contentRecord"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="data.reviewStatus == 1">
            <el-form-item align="right">
              <el-button @click="updataFollow">不回访</el-button>
              <el-button type="primary" @click="submitFollowForm('followUp')"
                >保存</el-button
              >
            </el-form-item>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getOrderDetail,
  createHandle,
  createReview,
  updateReviewStatus,
} from "@/api/ruge/lego/customerSeats/workOrderEvent";
export default {
  name: "detail",
  data() {
    return {
      data: {},
      isResults: false,
      isfollowUp: false,
      ruleForm: {
        name: null,
        department: null,
        processTime: null,
        processResults: null,
      },
      rules: {
        name: [{ required: true, message: "请输入处理人", trigger: "blur" }],
        department: [
          { required: true, message: "请输入处理部门", trigger: "blur" },
        ],
        processTime: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "blur",
          },
        ],
        processResults: [
          { required: true, message: "请输入处理结果", trigger: "blur" },
        ],
      },
      followUp: {
        situation: null,
        contentRecord: null,
      },
      rulefollow: {
        situation: [
          { required: true, message: "工单模板为必填项", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getTableList();
  },
  methods: {
    jumpToRwork() {
      this.$router.push({
        path: "/iot/orderQuery/detail",
        query: { orderId: this.data.orderId },
      });
    },
    updataFollow() {
      this.$confirm("您确认修改回访状态吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateReviewStatus({
            reviewStatus: "3",
            recordCode: this.data.recordCode,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功！");
              this.getTableList();
            } else {
              this.$message.warning("修改失败！");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    submitForm(formName) {
      //保存处理结果
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler(1);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitFollowForm(formName) {
      //保存回访
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler(2);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitHandler(saveView) {
      this.$confirm("您确认保存填写信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (saveView == 1) {
            this.saveResults();
          } else {
            this.saveFollowUp();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消保存",
          });
        });
    },
    saveFollowUp() {
      createReview({
        recordCode: this.data.recordCode,
        satisfactionLevel: this.followUp.situation,
        reviewContent: this.followUp.contentRecord,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("保存成功！");
          this.getTableList();
        } else {
          this.$message.warning("保存失败！");
        }
      });
    },
    saveResults() {
      let params = {
        recordCode: this.data.recordCode,
        handlePersonName: this.ruleForm.name,
        handleDept: this.ruleForm.department,
        handleTime: this.ruleForm.processTime,
        handleResult: this.ruleForm.processResults,
      };
      createHandle(params).then((res) => {
        if (res.code === 200) {
          this.$message.success("保存成功！");
          this.getTableList();
        } else {
          this.$message.warning("保存失败！");
        }
      });
    },
    getTableList() {
      getOrderDetail({
        id: this.$route.query.dataId,
      }).then((res) => {
        if (res) {
          this.data = res;
          if (res.recordHandleVO && res.recordHandleVO.handlePersonName) {
            this.isResults = true;
            this.ruleForm.name = res.recordHandleVO.handlePersonName;
            this.ruleForm.department = res.recordHandleVO.handleDept;
            this.ruleForm.processTime = new Date(
              res.recordHandleVO.handleTime
            ).getTime();
            this.ruleForm.processResults = res.recordHandleVO.handleResult;
          }
          if (res.recordReviewVO && res.recordReviewVO.recordCode) {
            this.isfollowUp = true;
            this.followUp.situation = res.recordReviewVO.satisfactionLevel;
            this.followUp.contentRecord = res.recordReviewVO.reviewContent;
          }
          console.log("this.followUp", this.followUp);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.paid-detail-component {
  position: relative;
  padding: 32px;
  margin: 32px;
  background: #fff;
  height: 85vh;
  overflow: auto;
  .paid-name {
    width: 96px;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #2f3941;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .paid-arrange {
    display: flex;
    flex-flow: row wrap;
    width: 874px;
    padding-bottom: 18px;
    .paid-arrange-setWidth {
      width: 874px !important;
    }
    .paid-arrange-sort {
      width: 436px;
      height: 42px;
      margin-bottom: 24px;
      .title {
        height: 18px;
        font-size: 12px;
        font-weight: 300;
        color: #2f3941;
        line-height: 18px;
      }
      .content {
        margin-top: 4px;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #2f3941;
        line-height: 20px;
      }
    }
    .workOrder {
      position: absolute;
      top: 536px;
      right: 36px;
      width: 116px;
      height: 36px;
      border: 1px solid #1a4cec;
      line-height: 36px;
      text-align: center;
      border-radius: 4px;
      font-size: 14px;
      font-family: Noto Sans SC, Noto Sans SC;
      font-weight: bold;
      color: #1a4cec;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>